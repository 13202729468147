import Link from 'next/link';
import { makeStyles } from '@material-ui/core';
import {
  Root as DropdownMenuRoot,
  Trigger,
  Portal,
  Group,
  Label,
  Arrow,
  Item,
} from '@radix-ui/react-dropdown-menu';
import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import { paletteV2 } from '../../theme/palette';
import UserAvatarButton from '../UserAvatar/UserAvatarButton';
import { MenuContent, MenuSeparator } from '../DropdownMenu/components';
import Typography from '../Typography';
import Icon from '../Icon';
import theme from '../../theme';

const useStyles = makeStyles(() => ({
  content: {
    // Needed to overlay menu on top of the navbar itself
    zIndex: theme.zIndex.tooltip,
    width: theme.spacing(26.25),
    backgroundColor: paletteV2.grey[900],
    '& div[role="menuitem"]': {
      '&:not([data-disabled=""])': {
        backgroundColor: paletteV2.grey[900],
        '&[data-highlighted]': {
          backgroundColor: paletteV2.grey[700],
        },
      },
    },
  },
  arrow: {
    fill: paletteV2.grey[900],
  },
  accountInfoContainer: {
    padding: theme.spacing(1, 1, 0.5),
  },
  name: {
    color: paletteV2.grey[0],
    fontSize: theme.typography.button.fontSize,
  },
  accountInfoSubLabel: {
    color: paletteV2.grey[75],
    paddingTop: theme.spacing(0.5),
  },
  capitalize: {
    textTransform: 'capitalize',
    padding: 0,
    marginRight: '4px',
  },
  truncateLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingBottom: '4px',
  },
  primaryAction: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    backgroundColor: paletteV2.grey[800],
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(0.5),
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: paletteV2.grey[800],
      color: theme.palette.primary.light,
    },
  },
  upgradeContainer: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(2.75),
  },
  iconMenuItem: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: paletteV2.grey[800],
    height: theme.spacing(4.25),
  },
  customIcon: { height: '22px', width: '22px' },
  customIconLabel: { paddingLeft: 0 },
}));

export interface AccountDropdownMenuProps
  extends Omit<AccountDropdownMenuContentProps, 'open'> {
  imageSrc?: string;
  disablePortal?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
}

export interface AccountDropdownMenuContentProps {
  userName: string;
  email: string;
  subscriptionName: string;
  showUpgrade: boolean;
  isAdmin: boolean;
  onUpgrade?: () => void;
  onSignOut?: () => void;
}

const AccountDropdownMenuContent = React.forwardRef<
  HTMLDivElement,
  AccountDropdownMenuContentProps
>((props, ref) => {
  const {
    userName,
    email,
    subscriptionName,
    showUpgrade,
    isAdmin,
    onUpgrade,
    onSignOut,
  } = props;

  const classes = useStyles();

  const minWidthOverride = {
    minWidth: 0,
    paddingRight: 0,
  };

  return (
    <MenuContent
      ref={ref}
      id="user-profile-menu"
      className={classes.content}
      side="right"
      sideOffset={16}
      collisionPadding={8}
      aria-label={`User profile menu. ${userName} is currently signed in. ${subscriptionName} is your current subscription`}
    >
      <Group>
        <Label className={classes.accountInfoContainer}>
          <Typography
            isLegacy
            variant="body2"
            className={clsx(classes.name, classes.capitalize)}
            aria-label={`${userName} is currently signed in.`}
          >
            {userName}
          </Typography>
          <Typography
            isLegacy
            variant="body2"
            className={clsx(classes.accountInfoSubLabel, classes.truncateLabel)}
            aria-label={`${subscriptionName} is your email`}
          >
            {email}
          </Typography>
          <div className={classes.upgradeContainer}>
            <Typography
              isLegacy
              variant="body2"
              className={clsx(classes.accountInfoSubLabel, classes.capitalize)}
              aria-label={`${subscriptionName} is your current subscription`}
            >{`${subscriptionName} Account`}</Typography>
            {showUpgrade && (
              <Item onSelect={onUpgrade}>
                <Typography
                  isLegacy
                  variant="body2"
                  color="primary"
                  className={classes.primaryAction}
                  style={{ minWidth: 0, padding: '4px' }}
                >{`Upgrade`}</Typography>
              </Item>
            )}
          </div>
        </Label>
        <MenuSeparator />
        <Link href={'/dashboard/account'}>
          <Item className={classes.iconMenuItem}>
            <Icon type="settings01" style={minWidthOverride} />
            <Typography
              color="grey-0"
              variant="body2"
            >{`Account settings`}</Typography>
          </Item>
        </Link>
        {isAdmin && (
          <Link href={`/dashboard/admin`}>
            <Item className={classes.iconMenuItem}>
              <Icon type="userCircle" style={minWidthOverride} />
              <Typography
                color="grey-0"
                variant="body2"
              >{`Admin portal`}</Typography>
            </Item>
          </Link>
        )}
        <Item
          onSelect={() => {
            window.open(`https://help.wellsaidlabs.com/`, '_blank');
          }}
          className={classes.iconMenuItem}
        >
          <Icon type="helpCircle" style={minWidthOverride} />
          <Typography
            color="grey-0"
            variant="body2"
          >{`Help center`}</Typography>{' '}
        </Item>
        <MenuSeparator />
        <Item onSelect={onSignOut} className={classes.iconMenuItem}>
          <Icon
            type="arrowCircle"
            style={{ ...minWidthOverride, paddingTop: theme.spacing(1.75) }}
            className={classes.customIcon}
          />
          <Typography
            color="grey-0"
            variant="body2"
            className={classes.customIconLabel}
            style={{
              ...minWidthOverride,
              paddingLeft: theme.spacing(0.25),
              paddingBottom: theme.spacing(1.5),
            }}
          >{`Sign out`}</Typography>
        </Item>
      </Group>
      <Arrow className={classes.arrow} />
    </MenuContent>
  );
});

const AccountDropdownMenu = (props: AccountDropdownMenuProps) => {
  const { imageSrc, disablePortal = false, onOpenChange, ...rest } = props;
  const router = useRouter();
  const [open, setOpen] = useState(false);

  const handleTriggerClick = useCallback(() => {
    setOpen(!open);
    if (onOpenChange) onOpenChange(!open);
  }, [onOpenChange, open]);

  /**
   * Because the user profile menu's tooltip has nav links,
   * We should close the tooltip when we redirect user to those pages
   */
  useEffect(() => {
    const routeChanged = () => {
      setOpen(false);
      if (onOpenChange) onOpenChange(false);
    };

    router?.events.on('routeChangeComplete', routeChanged);

    return () => {
      router?.events.off('routeChangeComplete', routeChanged);
    };
  }, [onOpenChange, router?.events, router?.route]);

  const handleOpenChange = (isOpen: boolean) => {
    setOpen(isOpen);
    if (onOpenChange) onOpenChange(isOpen);
  };

  return (
    <DropdownMenuRoot open={open} onOpenChange={handleOpenChange}>
      <Trigger asChild onClick={handleTriggerClick}>
        <UserAvatarButton open={open} imageSrc={imageSrc} />
      </Trigger>
      {disablePortal && <AccountDropdownMenuContent {...rest} />}
      {!disablePortal && (
        <Portal>
          <AccountDropdownMenuContent {...rest} />
        </Portal>
      )}
    </DropdownMenuRoot>
  );
};

export default AccountDropdownMenu;
