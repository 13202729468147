import React from 'react';
import clsx from 'clsx';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { SvgIconProps, makeStyles } from '@material-ui/core';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import Typography from '../../../Typography';
import paletteV2 from '../../../Typography/typographyPalette';
import Tooltip from '../../../Tooltip';

const useStyles = makeStyles(theme => ({
  tabItem: {
    cursor: 'pointer',
    borderRadius: '4px',
    textWrap: 'nowrap',
    '&:has(:focus-visible)': {
      ...theme.mixins.focusOutline,
      outlineOffset: -1.5,
    },
    '&:hover': {
      backgroundColor: paletteV2.grey[700],
    },
  },
  link: {
    color: paletteV2.grey[0],
    display: 'flex',
    justifyItems: 'stretch',
    justifyContent: 'start',
    gap: 8,
    padding: 8,
    paddingLeft: 12,
    borderRadius: '4px',
    '&[data-active]': {
      backgroundColor: paletteV2.grey[700],
      '&:hover': {
        backgroundColor: paletteV2.grey[900],
      },
    },
  },
  label: {
    opacity: 1,
    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.easeIn,
      delay: theme.transitions.duration.shortest,
    }),
  },
  hideLabel: {
    opacity: 0,
  },
  tooltip: {
    textTransform: 'capitalize',
  },
}));

interface NavigationTabProps {
  label: string;
  href: string;
  Icon: React.ElementType<SvgIconProps>;
  isLeftRailOpen: boolean;
  target?: string;
  additionalRoutes?: string[];
}

const NavigationTab = ({
  label,
  Icon,
  href,
  isLeftRailOpen,
  additionalRoutes,
  target = '_self',
}: NavigationTabProps) => {
  const classes = useStyles({ isLeftRailOpen });
  const router = useRouter();
  const pathname = router?.pathname;

  const isActive = React.useMemo(() => {
    if (pathname?.includes(href)) return true;
    if (additionalRoutes) {
      return Boolean(additionalRoutes.find(route => pathname?.includes(route)));
    }
    return false;
  }, [additionalRoutes, href, pathname]);

  return (
    <Tooltip
      text={label}
      classes={{ tooltip: classes.tooltip }}
      placement="right-end"
      PopperProps={{
        hidden: isLeftRailOpen,
      }}
    >
      <NavigationMenu.Item aria-label={label} className={clsx(classes.tabItem)}>
        <NextLink href={href} target={target} passHref>
          <NavigationMenu.Link
            active={isActive}
            className={classes.link}
            style={{ textDecoration: 'none' }}
          >
            <Icon fontSize="default" />
            <Typography
              variant="md"
              color="grey-0"
              className={clsx(classes.label, {
                [classes.hideLabel]: !isLeftRailOpen,
              })}
            >
              {label}
            </Typography>
          </NavigationMenu.Link>
        </NextLink>
      </NavigationMenu.Item>
    </Tooltip>
  );
};

export default NavigationTab;
