import {
  Box,
  DrawerProps,
  Hidden,
  makeStyles,
  Tabs,
  Theme,
} from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Link from 'next/link';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import DashboardLayoutNavigationDrawerTab from '../../layouts/dashboard/DashboardLayoutNavigationDrawerTab';
import { BreakpointOrNull } from '../../lib/useBreakpoint';
import Icon from '../Icon';
import IconButton from '../IconButton/IconButton';
import { routes } from './routes';
import StudioDrawer from './StudioDrawer';
import createSignoutProps from '../../lib/createSignoutProps';
import AccountDropdownMenu, {
  AccountDropdownMenuProps,
} from '../AccountDropdownMenu';
import { getTabIcon } from '../BaseLayout/components/Navigation/NavigationTabs';
import { useFeatureFlags } from '../FeatureFlags';

const ContentTileListLeftSiderail = dynamic(
  () => import('../ContentTiles/ContentTileListLeftSiderail'),
  { ssr: false }
);

export interface CollapsibleStudioDrawerProps
  extends DrawerProps,
    Omit<AccountDropdownMenuProps, 'onSignOut'> {
  currentTabValue: string;
  isBannerDisplayed: boolean;
  breakpoint: BreakpointOrNull;
  isAdmin: boolean;
  onDrawerChange: (open: boolean) => void;
  isStudioRoute: boolean;
}

const useStyles = makeStyles<Theme, CollapsibleStudioDrawerProps>(theme => ({
  indicator: {
    display: 'none',
  },
  logo: {
    cursor: 'pointer',
    height: theme.spacing(2),
    width: 'auto',
  },
  logoContainer: {
    [theme.breakpoints.down('sm')]: {
      minHeight: theme.mixins.toolbar.minHeight,
      padding: theme.spacing(0, 1, 0, 2),
      paddingBottom: ({ isBannerDisplayed }: CollapsibleStudioDrawerProps) =>
        isBannerDisplayed ? theme.spacing(6) : 0,
      position: 'relative',
      top: ({ isBannerDisplayed }: CollapsibleStudioDrawerProps) =>
        isBannerDisplayed ? theme.spacing(6) : 0,
    },
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 2),
  },
  drawer: {
    width: theme.spacing(8),
    zIndex: theme.zIndex.drawer + 2,
  },
  drawerOpen: {
    width: theme.spacing(30),
  },
  drawerClosed: {
    width: theme.spacing(8),
  },
  drawerContainer: {
    minWidth: theme.spacing(8),
    transition: theme.transitions.create(['width'], {
      easing: 'cubic-bezier(0, 0, 0.2, 1)',
      duration: 500,
    }),
    [theme.breakpoints.up('md')]: {
      outline: `1px solid ${theme.palette.background.paper}`,
    },
  },
  tab: {
    paddingLeft: theme.spacing(2),
  },
  userProfile: {
    position: 'fixed',
    bottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
}));

const COLLAPSABLE_BREAKPOINTS: Array<Breakpoint> = ['xs', 'sm'];

const CollapsibleStudioDrawer = (props: CollapsibleStudioDrawerProps) => {
  const {
    onUpgrade,
    onDrawerChange,
    onClose,
    open,
    currentTabValue,
    isAdmin,
    isBannerDisplayed,
    breakpoint,
    userName,
    email,
    subscriptionName,
    imageSrc,
    isStudioRoute,
    showUpgrade,
    ...restProps
  } = props;
  const classes = useStyles(props);
  const [tabsValue, setTabsValue] = useState<string>(currentTabValue);
  const { isFeatureEnabled } = useFeatureFlags();
  const tabsRef = useRef(null);
  const [previousBreakpoint, setPreviousBreakpoint] =
    useState<BreakpointOrNull>();

  const router = useRouter();
  const [isMenuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    setTabsValue(currentTabValue);
  }, [currentTabValue, tabsValue]);

  // Add responsiveness to ensure that drawer is collapsed
  useEffect(() => {
    if (breakpoint) {
      // If user keeps the drawer open on smaller view but then expands
      // the screen, close drawer so that the user automatically sees the collapsed view
      // once they're in the larger breakpoints
      const currentBreakpointIsLarger =
        !COLLAPSABLE_BREAKPOINTS.includes(breakpoint);
      const previouslySmallBreakpoint =
        previousBreakpoint &&
        COLLAPSABLE_BREAKPOINTS.includes(previousBreakpoint);

      if (previouslySmallBreakpoint && currentBreakpointIsLarger) {
        onDrawerChange(false);
      }

      setPreviousBreakpoint(breakpoint);
    }
  }, [breakpoint, onDrawerChange, previousBreakpoint]);

  const handleMouseEvent = (isOpen: boolean) => {
    // If we're in smaller view, don't listen to the mouse events
    // Use same functionality as in mobile
    if (breakpoint && !COLLAPSABLE_BREAKPOINTS.includes(breakpoint)) {
      onDrawerChange(isOpen);
    }
  };

  const navigationTabs = routes.filter(item => {
    const { label, featureFlag } = item;
    if (featureFlag && !isFeatureEnabled(featureFlag)) {
      return false;
    }
    if (label.includes('admin')) {
      return isAdmin;
    }
    return true;
  });

  // If we are already in a project, the studio tab doesn't need to redirect to the studio page.
  // so we disable the studio button
  const isTabDisabled = (tabLabel: string) => {
    return (
      tabLabel === 'studio' && router?.route === '/dashboard/studio/[projectId]'
    );
  };

  const onCloseDrawer = useCallback(
    event => {
      if (typeof onClose === 'function') onClose(event, 'backdropClick');
    },
    [onClose]
  );

  const signoutProps = createSignoutProps();

  /**
   * Let the user account dropdown menu take precedence
   * If this menu is open, override and let menu control if navbar should be open or close
   */
  const handleMouseLeave = () => {
    if (!isMenuOpen) {
      handleMouseEvent(false);
    }
  };

  return (
    <StudioDrawer
      {...restProps}
      PaperProps={{
        className: clsx(classes.drawerContainer, {
          [classes.drawerOpen]: open,
          [classes.drawerClosed]: !open,
        }),
      }}
      anchor="left"
      variant={
        breakpoint && COLLAPSABLE_BREAKPOINTS.includes(breakpoint)
          ? 'temporary'
          : 'permanent'
      }
      withReducedHeight={isBannerDisplayed}
      open={open}
      onClose={onCloseDrawer}
      aria-controls={'wsl-navigation-tabs'}
      className={classes.drawer}
      onMouseOver={() => handleMouseEvent(true)}
      onMouseEnter={() => handleMouseEvent(true)}
      onMouseLeave={handleMouseLeave}
    >
      <Box className={classes.logoContainer}>
        <Link href="/dashboard/projects">
          <img
            className={classes.logo}
            src="/static/images/logo/mark.svg"
            alt="Logo"
          />
        </Link>
        <Hidden mdUp>
          <IconButton
            aria-label="main navigation close icon"
            onClick={onCloseDrawer}
          >
            <Icon type="chevronLeft" />
          </IconButton>
        </Hidden>
      </Box>
      <nav id={'wsl-navigation-tabs'} aria-label="navigation tabs">
        <Tabs
          value={tabsValue}
          orientation="vertical"
          scrollButtons="off"
          classes={{ indicator: classes.indicator }}
          ref={tabsRef}
        >
          {navigationTabs.map(item => (
            <DashboardLayoutNavigationDrawerTab
              key={item.route}
              className={classes.tab}
              href={item.route}
              value={item.route}
              label={item.label}
              Icon={getTabIcon(item.iconType)}
              a11yLabel={item.label}
              disabled={isTabDisabled(item.label)}
            />
          ))}
        </Tabs>
        {tabsRef && open && <ContentTileListLeftSiderail tabsRef={tabsRef} />}
      </nav>
      <Hidden smDown={!isStudioRoute}>
        <Box className={classes.userProfile}>
          <AccountDropdownMenu
            userName={userName}
            email={email}
            subscriptionName={subscriptionName}
            showUpgrade={showUpgrade}
            isAdmin={isAdmin}
            imageSrc={imageSrc}
            onUpgrade={onUpgrade}
            onSignOut={signoutProps.onClick}
            onOpenChange={isOpen => {
              onDrawerChange(isOpen);
              setMenuOpen(isOpen);
            }}
          />
        </Box>
      </Hidden>
    </StudioDrawer>
  );
};

export default CollapsibleStudioDrawer;
